import { Box, Button, Grid, Typography, Select, MenuItem } from "@mui/material";
import CountDown from "../../SmallComponent/CountDown";
import { CustomField } from "../../SmallComponent/CustomFeild";
import { bnb, ether, logo, usdt } from "../../Images";
import {
  mainnet,
  useAccount,
  useBalance,
  useContractRead,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useCallback, useEffect, useState } from "react";
import { getNetworkContract } from "../../ConnectivityAssets/environment";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "@ethersproject/units";
import Loading from "../../ConnectivityAssets/loading";
import Toastify from "../../ConnectivityAssets/Toastify";
import { bsc } from "viem/chains";
import preSaleETHAbi from "../../ConnectivityAssets/preSaleETHAbi.json";
import preSaleBSCAbi from "../../ConnectivityAssets/preSaleBSCAbi.json";
import { readContract } from "wagmi/actions";

const BuyCard = ({ estimatedPrice }) => {
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [youWillGet, setYouWillGet] = useState(0);
  const [showUserRoundData, setShowUserRoundData] = useState(false);
  const [userTotalToken, setUserTotalToken] = useState(0);

  const [payWith, SetPayWith] = useState({
    icon: ether,
    name: "ETH",
    chainid: mainnet.id,
  });

  const { address } = useAccount();
  const { chain } = useNetwork();

  const networkContract = getNetworkContract(chain?.id ?? 1);

  const { switchNetworkAsync, switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal();

  const { data: balanceData } = useBalance({
    address,
    token: payWith?.name === "USDT" ? networkContract.usdtAddress : undefined,
    chainId: chain?.id,
  });

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const [totalRaiseUsdt, setTotalRaisedUsdt] = useState(0);

  const [activeCurrency, setActiveCurrency] = useState({
    icon: ether,
    name: "ETH",
    chainid: mainnet.id,
  });

  const handleCurrencySelection = async (currency) => {
    setLoading(true);
    try {
      if (address) {
        if (chain?.id === currency?.chainid) {
          showToast(`Already On ${currency.name} chain`);
        } else {
          await switchNetworkAsync(currency?.chainid);
          setActiveCurrency(currency);
          SetPayWith({
            icon: currency.icon,
            name: currency.name,
            chainid: currency.chainid,
          });
          setYouWillGet(0);
          setAmount(0);
        }
      } else {
        showToast("Connect Wallet");
      }
    } catch (error) {
      console.error(error);
      showToast(`${error}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const showToast = (msg, type) => {
    return setAlertState({
      open: true,
      message: msg,
      severity: type,
    });
  };

  const decimals = chain?.id === mainnet.id ? 6 : chain?.id === bsc.id ? 18 : 6;

  const connectionHandler = useCallback(() => {
    if (address) {
      return open();
    }
    open();
  }, [address, open]);

  const init2 = useCallback(async () => {
    try {
      const RaisedEth = await readContract({
        address: "0xb38eF8634E7263c408382024897629938aCf5CC0",
        abi: preSaleETHAbi,
        functionName: "getTotalInUSDT",
        args: [],
        chainId: 1,
      });

      const RaisedBNB = await readContract({
        address: "0xD172F6c4B96D3367052f839F68aa95B6B8428335",
        abi: preSaleBSCAbi,
        functionName: "getTotalInUSDT",
        args: [],
        chainId: 56,
      });

      const totalRaisedEth = Number(formatUnits(RaisedEth, 18));
      const totalRaisedBNB = Number(formatUnits(RaisedBNB, 18));
      const totalRaised = totalRaisedEth + totalRaisedBNB + 39867;
      setTotalRaisedUsdt(Number(totalRaised.toFixed(0)));

      //------------------------ BSC , ETH  Total Token  ----------------------------

      const { data: currentRound } = await readContract({
        address: "0xb38eF8634E7263c408382024897629938aCf5CC0",
        abi: preSaleETHAbi,
        functionName: "currentRound",
        chainId: 1,
      });
      console.log("currentRound", currentRound);

      const fetchUserRoundData = async (currentRound) => {
        let totalSumRawETH = 0n; // Accumulate raw values as BigInt

        for (let round = 0; round <= 0; round++) {
          const data = await readContract({
            address: "0xb38eF8634E7263c408382024897629938aCf5CC0",
            abi: preSaleETHAbi,
            functionName: "userRoundData",
            args: [address, round],
            chainId: 1,
          });

          // Add raw BigInt values
          totalSumRawETH += data[0]; // Assuming data[0] is the first BigInt
          totalSumRawETH += data[1]; // Assuming data[1] is the second BigInt
        }

        // Now format the final sum
        const totalSumFormatted = Number(formatUnits(totalSumRawETH, 18));
        // console.log("Total Sum ETH:", totalSumFormatted); // Print the formatted result

        // ---------------------- BSC Total -------------------------------
        let totalSumRawBNB = 0n; // Accumulate raw values as BigInt

        for (let round = 0; round <= 0; round++) {
          const data = await readContract({
            address: "0xD172F6c4B96D3367052f839F68aa95B6B8428335",
            abi: preSaleBSCAbi,
            functionName: "userRoundData",
            args: [address, round],
            chainId: 56,
          });

          // Add raw BigInt values
          console.log("data", data);
          totalSumRawBNB += data[0]; // Assuming data[0] is the first BigInt
          totalSumRawBNB += data[1]; // Assuming data[1] is the second BigInt
        }

        // Now format the final sum
        const totalSumFormattedBNB = Number(formatUnits(totalSumRawBNB, 18));
        const totalToken = totalSumFormatted + totalSumFormattedBNB;
        // console.log("totalToken", totalToken);
        setUserTotalToken(totalToken.toFixed(0));
      };
      if (address) {
        fetchUserRoundData();
      }

      // console.log("total Raised", totalRaised);
    } catch (error) {
      console.log("error", error);
    }
  }, [address]);

  useEffect(() => {
    init2();
  }, [init2]);

  const onAmountChange = useCallback(
    async (e) => {
      try {
        let inputAmount;
        if (e) {
          inputAmount = e.target.value;
          setAmount(inputAmount);
          console.log(inputAmount);
        } else {
          inputAmount = amount;
        }

        if (
          inputAmount === "" ||
          inputAmount === "0" ||
          isNaN(Number(inputAmount))
        ) {
          setYouWillGet(0);
          setLoading(false);
          return;
        }

        if (payWith?.name === "ETH") {
          setLoading(true);
          const ethToToken = await preSaleReadFunction(
            networkContract,
            "ethToToken",
            [parseUnits(inputAmount, 18).toString()]
          );
          setLoading(false);
          setYouWillGet(Number(formatUnits(ethToToken, 18)).toFixed(2));
        }
        if (payWith?.name === "BNB") {
          setLoading(true);
          const bnbToToken = await preSaleReadFunction(
            networkContract,
            "ethToToken",
            [parseUnits(inputAmount, 18).toString()]
          );

          setYouWillGet(Number(formatUnits(bnbToToken, 18)).toFixed(2));
          setLoading(false);
        }

        if (payWith.name === "USDT") {
          setLoading(true);
          const usdtToToken = await preSaleReadFunction(
            networkContract,
            "usdtToToken",
            [parseUnits(inputAmount, decimals).toString()]
          );
          setLoading(false);
          setYouWillGet(Number(formatUnits(usdtToToken, decimals)).toFixed(2));
        }
      } catch (error) {
        setLoading(false);
        console.log("Error ....", error);
      }
    },
    [amount, decimals, networkContract, payWith.name]
  );

  const buyHandler = async (e) => {
    e.preventDefault();
    if (isNaN(Number(amount))) {
      showToast("Invalid Input !", "error");
      return;
    }

    try {
      setLoading(true);

      if (payWith.name === "ETH") {
        const res = await preSaleWriteFunction(
          networkContract,
          "buyWithETH",
          [],
          parseUnits(amount, 18).toString()
        );
        setLoading(false);
      }
      if (payWith.name === "BNB") {
        const res = await preSaleWriteFunction(
          networkContract,
          "buyWithETH",
          [],
          parseUnits(amount).toString()
        );
        setLoading(false);
      }

      if (payWith.name === "USDT") {
        const allow = await usdtReadFunction(networkContract, "allowance", [
          address,
          networkContract.presaleAddress,
        ]);

        if (Number(formatUnits(allow, decimals)) < Number(amount)) {
          const hash = await usdtWriteFunction(networkContract, "approve", [
            networkContract.presaleAddress,
            parseUnits(amount.toString(), decimals).toString(),
          ]);
        }

        const res = await preSaleWriteFunction(networkContract, "buyWithUSDT", [
          parseUnits(amount, decimals).toString(),
        ]);

        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(error.message, "error");
      console.log("Error ...", error);
    }
  };

  const style = document.createElement("style");
  style.innerHTML = `
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
`;
  document.head.appendChild(style);

  const handleMaxClick = () => {
    if (balanceData?.formatted) {
      setAmount(balanceData.formatted);
      onAmountChange({ target: { value: balanceData.formatted } });
    } else {
      showToast("Failed to fetch balance!", "error");
    }
  };

  return (
    <Box sx={{}}>
      <Loading isLoading={loading} />
      <Toastify setAlertState={setAlertState} alertState={alertState} />
      <Box
        sx={{
          borderRadius: "20px",
          py: 2,
          px: 1,
          backdropFilter: "blur(14px)",
          background: "#000",
          display: "flex",
          flexDirection: "column",
          gap: "10px 0px",
          border: "1px solid #71767A",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "20px",
              color: "#fff",
              textAlign: "center",
              fontFamily: "HelveticaNeueBlackBold",
            }}
          >
            Ends in Round 4
          </Typography>
        </Box>
        <Box
          sx={{
            py: 1,
          }}
        >
          <CountDown />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "center",
            py: 1,
          }}
        >
          <Box>
            {" "}
            <Box>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "#fff",
                  fontSize: { xs: "14px", md: "17px" },
                  textAlign: "center",
                  display: "flex",
                  gap: "0 10px",
                  alignItems: "center",
                  fontFamily: "HelveticaNeueBlack",
                }}
              >
                <Box
                  sx={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "50%",
                    background: "red",
                    animation: "blink 0.7s infinite",
                    fontFamily: "HelveticaNeueBlack",
                  }}
                />{" "}
                On Air Estimated
              </Typography>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "#fff",
                  fontSize: { xs: "14px", md: "17px" },
                  textAlign: "center",
                  fontFamily: "HelveticaNeueBlack",
                }}
              >
                Listing Price : ${estimatedPrice}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px 20px",
          }}
        >
          <ChainSelect
            handleClick={handleCurrencySelection}
            payWith={payWith}
          />

          <TokenSelect
            payWith={payWith}
            SetPayWith={SetPayWith}
            chain={chain}
            setAmount={setAmount}
            setYouWillGet={setYouWillGet}
          />
        </Box>

        <Box sx={{ pt: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0px 20px",
              py: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "14px", sm: "18px", md: "20px" },
                color: "#fff",
                fontFamily: "HelveticaNeueBlack",
              }}
            >
              1 $YTP = $0.0284
            </Typography>
          </Box>
          <Typography
            sx={{
              textTransform: "uppercase",
              color: "#fff",
              fontSize: { xs: "14px", md: "17px" },
              textAlign: "center",
              fontFamily: "HelveticaNeueBlack",
            }}
          >
            {/* USDT Raised : ${totalRaiseUsdt.toLocaleString()}? */}
            {address && (
              <>Your Purchased $YTP : {userTotalToken.toLocaleString()} </>
            )}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "20px 10px",
            justifyContent: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px 0" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px 0px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: "300",
                  ml: 2,
                  fontFamily: "HelveticaNeueBlack",
                }}
              >
                Pay With {payWith?.name}
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: "300",
                  fontFamily: "HelveticaNeueBlack",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={handleMaxClick}
              >
                Max
              </Typography>
            </Box>

            <CustomField
              color="#000"
              placeholder="0"
              name="amount"
              image={payWith?.icon}
              value={amount}
              onChange={onAmountChange}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px 0" }}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "12px",
                fontWeight: "300",
                ml: 2,
                fontFamily: "HelveticaNeueBlack",
              }}
            >
              $YTP you receive
            </Typography>

            <CustomField
              color="#000"
              placeholder="0"
              name="youWillGet"
              image={logo}
              value={youWillGet}
              readOnly
            />
          </Box>
        </Box>

        <Box>
          <Button
            onClick={(e) => {
              buyHandler(e);
            }}
            sx={{
              fontSize: "13px",
              background: "#fff",
              borderRadius: "15px",
              border: "1px solid transparent",
              width: "100%",
              color: "#000",
              fontFamily: "HelveticaNeueBlack",

              "&:hover": {
                border: "1px solid #fff",
              },
            }}
          >
            Buy With {payWith?.name}
          </Button>
        </Box>
        <Button
          onClick={connectionHandler}
          sx={{
            background: "#fff",
            color: "#000",
            borderRadius: "15px",
            fontFamily: "HelveticaNeueBlack",
            "&:hover": {
              background: "#fff",
            },
          }}
        >
          {address ? "Disconnect" : " Connect Wallet"}
        </Button>
      </Box>
    </Box>
  );
};

export default BuyCard;

function ChainSelect({ handleClick, payWith }) {
  const chainArray = [
    {
      icon: bnb,
      name: "BSC",
      chainid: bsc.id,
    },
    {
      icon: ether,
      name: "ETH",
      chainid: mainnet.id,
    },
  ];

  const selectedChain =
    chainArray.find((chain) => chain.chainid === payWith?.chainid) ||
    chainArray[0];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
      }}
    >
      <Typography color="#fff" sx={{ fontFamily: "HelveticaNeueBlack" }}>
        Select Chain
      </Typography>

      <Box
        sx={{
          width: "100%",
          background: "#1A1B1F",
          borderRadius: "12px",
          border: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      >
        <Select
          value={selectedChain.chainid}
          onChange={(e) => {
            const selected = chainArray.find(
              (chain) => chain.chainid === e.target.value
            );
            if (selected) {
              handleClick(selected);
            }
          }}
          fullWidth
          sx={{
            color: "#fff",
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
            "&:hover .MuiOutlinedInput-notchedOutline": { border: "none" },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSvgIcon-root": { color: "#fff" },
          }}
          renderValue={(selected) => {
            const chain =
              chainArray.find((chain) => chain.chainid === selected) ||
              chainArray[0];
            return (
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <img
                  src={chain.icon}
                  alt=""
                  style={{ width: "24px", height: "24px", borderRadius: "50%" }}
                />
                <Typography sx={{ fontFamily: "HelveticaNeueBlack" }}>
                  {chain.name}
                </Typography>
              </Box>
            );
          }}
        >
          {chainArray.map((chain) => (
            <MenuItem
              key={chain.chainid}
              value={chain.chainid}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
              }}
            >
              <img
                src={chain.icon}
                alt=""
                style={{ width: "24px", height: "24px", borderRadius: "50%" }}
              />
              <Typography sx={{ fontFamily: "HelveticaNeueBlack" }}>
                {chain.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
}

function TokenSelect({ payWith, SetPayWith, chain, setAmount, setYouWillGet }) {
  const getTokenArray = () => {
    if (!chain?.id || chain?.id === mainnet.id) {
      return [
        {
          icon: ether,
          name: "ETH",
          chainid: mainnet.id,
        },
      ];
    } else if (chain?.id === bsc.id) {
      return [
        {
          icon: bnb,
          name: "BNB",
          chainid: bsc.id,
        },
        {
          icon: usdt,
          name: "USDT",
          chainid: bsc.id,
        },
      ];
    }
    return [];
  };

  const tokenArray = getTokenArray();
  const selectedToken =
    tokenArray.find((token) => token?.name === payWith?.name) || tokenArray[0];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
      }}
    >
      <Typography color="#fff" sx={{ fontFamily: "HelveticaNeueBlack" }}>
        Select Token
      </Typography>
      <Box
        sx={{
          width: "100%",
          background: "#1A1B1F",
          borderRadius: "12px",
          border: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      >
        <Select
          value={selectedToken?.name}
          onChange={(e) => {
            const selected = tokenArray.find(
              (token) => token?.name === e.target.value
            );
            if (selected) {
              SetPayWith(selected);
              setAmount(0);
              setYouWillGet(0);
            }
          }}
          fullWidth
          sx={{
            color: "#fff",
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
            "&:hover .MuiOutlinedInput-notchedOutline": { border: "none" },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSvgIcon-root": { color: "#fff" },
          }}
          renderValue={(selected) => {
            const token =
              tokenArray.find((token) => token?.name === selected) ||
              tokenArray[0];
            return (
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <img
                  src={token.icon}
                  alt=""
                  style={{ width: "24px", height: "24px", borderRadius: "50%" }}
                />
                <Typography sx={{ fontFamily: "HelveticaNeueBlack" }}>
                  {token?.name}
                </Typography>
              </Box>
            );
          }}
        >
          {tokenArray.map((token) => (
            <MenuItem
              key={token?.name}
              value={token?.name}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
              }}
            >
              <img
                src={token.icon}
                alt=""
                style={{ width: "24px", height: "24px", borderRadius: "50%" }}
              />
              <Typography sx={{ fontFamily: "HelveticaNeueBlack" }}>
                {token?.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
}
