import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { binance, bitget, bitmart, kuCoin, okx } from "../../Images";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import VerifiedIcon from "@mui/icons-material/Verified";

const futureListingArray = [
  {
    img: binance,
    text: "Binance",
    text2: "@binance",
  },
  {
    img: kuCoin,
    text: "KuCoin",
    text2: "@kucoincom",
  },
  {
    img: okx,
    text: "OKX",
    text2: "@okx",
  },
  {
    img: bitget,
    text: "Bitget",
    text2: "@bitgetglobal",
  },
  {
    img: bitmart,
    text: "BitMart",
    text2: "@BitMartExchange",
  },
];

const FutureListing = () => {
  return (
    <Box
      sx={{ borderRadius: "20px", border: "1px solid #71767A", px: 3, py: 2 }}
    >
      <Box>
        <Typography
          sx={{
            color: "white",
            fontWeight: "bold",
            fontSize: "20px",
            pb: 1,
            fontFamily: "HelveticaNeueBlack",
          }}
        >
          Future Listing
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px 0" }}>
        {futureListingArray.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{ display: "flex", gap: "0 10px", alignItems: "center" }}
              >
                <Box sx={{ width: "40px", height: "40px" }}>
                  <img
                    src={item.img}
                    alt=""
                    srcset=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box>
                  <Box sx={{ display: "flex", gap: "0 5px" }}>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "18px",
                        fontWeight: 500,
                        fontFamily: "HelveticaNeueBlack",
                      }}
                    >
                      {item.text}
                    </Typography>
                    <Box>
                      <VerifiedIcon
                        sx={{ color: "#D4B93B", fontSize: "15px", mt: 0.5 }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    sx={{ color: "grey", fontFamily: "HelveticaNeueBlack" }}
                  >
                    {item.text2}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ color: "white" }}>
                <MoreHorizSharpIcon sx={{ fontSize: "24px", color: "#fff" }} />
              </Box>
            </Box>
          );
        })}
      </Box>

      <Typography
        sx={{ color: "#1DA1F2", fontSize: "18px", pt: 2, cursor: "pointer" }}
      >
        Show More
      </Typography>
    </Box>
  );
};

export default FutureListing;
