import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { tokenomics, logo } from "../Images";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import IosShareIcon from "@mui/icons-material/IosShare";
import WhySlider from "./TokenomicsSwiper";
import Icons from "../SmallComponent/Icons";

const iconArray = [
  {
    icon: (
      <ChatBubbleOutlineOutlinedIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "17k",
  },
  {
    icon: (
      <RepeatIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "190k",
  },
  {
    icon: (
      <FavoriteBorderIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "13M",
  },
  {
    icon: (
      <BookmarkBorderIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "107k",
  },
  {
    icon: (
      <IosShareIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "202k",
  },
];

const array = [
  {
    value: "Presale",
    text: "50% of the total supply is allocated to the presale stage, ensuring sufficient liquidity and participation to kickstart the project.",
    color: "#5775CF",
  },
  {
    value: "Liquidity",
    text: "20% is reserved to maintain healthy liquidity across exchanges, ensuring smooth trading and stability.",
    color: "#FFDC60",
  },

  {
    value: "Marketing",
    text: "20% is allocated to marketing efforts, including partnerships with influencers, social media promotions, and extensive campaigns to drive mainstream adoption.",
    color: "#9FE080",
  },
  {
    value: "Comunity",
    text: "10% is allocated to marketing efforts, including partnerships with influencers, social media promotions, and extensive campaigns to drive mainstream adoption.",
    color: "#EE6666",
  },
];

const Tokenomics = () => {
  return (
    <Box
      sx={{
        mt: -3,
        // boxSizing: "border-box",
        borderBottom: "1px solid #2F3336",
      }}
      id="Tokenomics"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 1, lg: 2 },
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", gap: "0 10px", boxSizing: "border-box" }}>
          <Box sx={{ width: { xs: "25px", md: "40px" } }}>
            <img
              src={logo}
              alt=""
              srcSet=""
              style={{
                width: "100%",
                objectFit: "contain",
                borderRadius: "49%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "calc(100% - 35px)" },
              // border: "2px solid red",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: "0px 10px" }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontFamily: "HelveticaNeueBlackBold",
                  }}
                >
                  YourTrump
                </Typography>
                <Typography sx={{ color: "#4B4D51" }}>$YTP</Typography>
              </Box>
              <Box>
                <MoreHorizSharpIcon
                  sx={{
                    fontSize: "24px",
                    color: "#757575",
                    fontFamily: "HelveticaNeueBlack",
                  }}
                />
              </Box>
            </Box>

            <Box>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "30px 0",
                  }}
                >
                  <Grid item xs={12} md={12}>
                    <Box>
                      {array.map((item, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              width: "100%",
                              mx: "auto",
                              display: "flex",
                              gap: { xs: "10px 10px ", md: "30px 30px" },
                              mb: 1,
                              borderRadius: "20px",
                              p: 1,
                              // px: 2,
                            }}
                          >
                            <Box
                              sx={{
                                // ml: "17px",
                                width: "100%",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontWeight: "600px",
                                  fontSize: { xs: "14px", sm: "18px" },
                                  fontFamily: "HelveticaNeueBlack",
                                }}
                              >
                                {" "}
                                {item.value}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  fontSize: { xs: "14px", md: "16px" },
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: { xs: "14px", sm: "16px" },
                                    fontFamily: "HelveticaNeueBlack",
                                  }}
                                >
                                  {item.text}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5.8}
                    sx={{
                      mx: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ width: "100%", height: "100%", mx: "auto" }}>
                      <img
                        src={tokenomics}
                        alt=""
                        srcSet=""
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          height: "100%",
                          borderRadius: "15px",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5.8}
                    sx={{
                      mx: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <WhySlider />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "30px 0",
                  }}
                >
                  {/* Tokenomics Image */}
                  <Grid
                    item
                    xs={12}
                    md={5.8}
                    sx={{
                      order: { xs: 1, sm: 1, md: 0 },
                      mx: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ width: "100%", height: "100%", mx: "auto" }}>
                      <img
                        src={tokenomics}
                        alt=""
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          height: "100%",
                          borderRadius: "15px",
                        }}
                      />
                    </Box>
                  </Grid>

                  {/* Text Array */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      order: { xs: 2, sm: 2, md: 0 },
                    }}
                  >
                    <Box>
                      {array.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            mx: "auto",
                            display: "flex",
                            gap: { xs: "10px 10px ", md: "30px 30px" },
                            mb: 1,
                            borderRadius: "20px",
                            p: 1,
                          }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <Typography
                              sx={{
                                color: "#fff",
                                fontWeight: "600px",
                                fontSize: { xs: "14px", sm: "18px" },
                                fontFamily: "HelveticaNeueBlack",
                              }}
                            >
                              {item.value}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: { xs: "14px", md: "16px" },
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: { xs: "14px", sm: "16px" },
                                  fontFamily: "HelveticaNeueBlack",
                                }}
                              >
                                {item.text}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Grid>

                  {/* Tokenomics Swiper */}
                  <Grid
                    item
                    xs={12}
                    md={5.8}
                    sx={{
                      order: { xs: 3, sm: 3, md: 0 },
                      mx: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <WhySlider />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Box sx={{ py: 3 }}>
                <Box sx={{ width: "100%", height: "100%", mx: "auto" }}>
                  <img
                    src={tokenomics}
                    alt=""
                    srcSet=""
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      height: "100%",
                      borderRadius: "15px",
                    }}
                  />
                </Box>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: 2 }}>
        <Box
          sx={{
            px: 2,
            pl: { xs: 4, sm: 4, md: 8 },
            mt: -2,
          }}
        >
          <Icons reply="50K" reTweet="155K" like="884K" view="156M" />
        </Box>
      </Box>
    </Box>
  );
};

export default Tokenomics;
